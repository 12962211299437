import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from 'rxjs';
import { NavParams } from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class DataService {
    private subject = new ReplaySubject<any>();
    
    private paramData = [];

    constructor() { }

    setData(params: NavParams) {
        this.subject.next({ params });
    }

    getData(): Observable<any> { return this.subject; }

    setParamData(id, data) {
        this.paramData[id] = data;
    }
     
    getParamData(id) {
        return this.paramData[id];
    }

}