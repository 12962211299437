import { GridCellElementInterface } from './grid-cell-element-interface';

import { Constants } from '../../config/constants'
import { StoreService } from '../../services/api/store.service'

import { Article } from '../../models/article';

export class ArticleGridCellElement implements GridCellElementInterface<Article> {
    constructor (private article: Article, private storeService: StoreService) {}
    private get store() { return this.storeService.getCurrentStore(); }
    get label() { return this.article.label }
    get description() { return this.article.description }
    get unitPrice() {
        return  this.article.isSupplement ?
                this.article.supplement :
                this.article.getPrice(this.store ? this.store.id : null)
    };
    get highlightPrice() { return false }
    get priceLayerPrice() { return this.article.priceLayerPrice }
    get articleOfferPrice() { return this.article.articleOfferPrice }
    get articleOfferLabel1() { return this.article.articleOfferLabel1 }
    get articleOfferLabel2() { return this.article.articleOfferLabel2 }
    get ratePrice() { return this.article.getPricePerUnit(this.store ? this.store.id : null) };
    get image() { return `${Constants.BASE_ARTICLES_IMAGE_URL + this.article.code}_0.png`; }
    get alt() { return this.article.label }
    get object() { return this.article };
    get isSelected() { return this.article.isChecked }
    get isMultiple() { return this.article.isMultiple }
    get isAvailable() { return this.article.getisAvailable(this.store ? this.store.id : null) }
    get isDelivery() { return this.article.isDelivery }
    get isComingSoon() { const stock = this.article.getStock(this.store ? this.store.id : null); return stock && stock.comingSoon }
    get hasInfo() { return Boolean(this.article.description || this.article.attributes.length != 0) }
}