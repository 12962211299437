import { Component, OnInit, Input } from '@angular/core';
import { NavController, NavParams, ModalController } from '@ionic/angular';
import { SocietyService }           from '../../services/api/society.service';
import { Society }                  from '../../models/society';
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

@Component({
	selector: 'app-gcs',
	templateUrl: './gcs.page.html',
	styleUrls: ['./gcs.page.scss'],
})
export class GcsPage implements OnInit {
	
	society: Society;

	returnUrl: string; 
	title:any; 
	url:any;  

	constructor(
		private societyService: SocietyService,
	    public navCtrl:   NavController,
	    public navParams: NavParams,
	    public router: Router,
	    private route: ActivatedRoute,
	    private modalCtrl: ModalController
	) {
	    this.society = new Society();
	}

	protected ionViewWillEnter() {
	    let that = this
	    this.societyService.callApiForSociety().subscribe(
	      (data) =>  { that.society = new Society(data); },
	      (error) => { console.log('society error on load') }
	    )
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			if(this.router.getCurrentNavigation()){
		      	if (this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state !== undefined) {
		        	this.returnUrl = this.router.getCurrentNavigation().extras.state.returnUrl;
		        	this.title = this.router.getCurrentNavigation().extras.state.title;
		        	this.url = this.router.getCurrentNavigation().extras.state.url;
		      	}
			}
	    });
	}

	goToProfilePage(){
		console.log('Return Url: ',this.returnUrl);

		this.modalCtrl.getTop().then(modal => {
          	if(modal){
          		this.modalCtrl.dismiss({
					'dismissed': true,
					'locale': null
				});
          	}else{
             	if(this.returnUrl){
					this.router.navigate([this.returnUrl]);
				}else{
			    	this.router.navigate(['tabs/profile']);
				}
          	}
       }); 
		
	}

}
