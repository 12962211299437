import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Article } from '../models/article';
import { ArticleGridCellElement } from '../components/grid-cell/article-grid-cell-element';
import { StoreService } from '../services/api/store.service';

/**
 * Generated class for the Article2GridElementPipe pipe.
 *
 * See https://angular.io/docs/ts/latest/guide/pipes.html for more info on
 * Angular Pipes.
 *
 * Transforme un article en grid-cell-element
*/

@Pipe({
    name: 'article2GridElement',
})
@Injectable()
export class Article2GridElementPipe implements PipeTransform {
    constructor(private storeService: StoreService) {}
    transform(article: Article) {
        return new ArticleGridCellElement(article, this.storeService);
    }
}
