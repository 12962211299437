import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Article2GridElementPipe } from './article-2-grid-element';
import { ArticleNotShowPipe } from './article-not-show';



@NgModule({
  declarations: [Article2GridElementPipe, ArticleNotShowPipe],
  imports: [
    CommonModule
  ],
  exports: [Article2GridElementPipe, ArticleNotShowPipe]
})
export class PipeModule { }
