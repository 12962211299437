import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController, NavParams, NavController, } from '@ionic/angular';

import { ENTER_STATE, LEAVE_STATE, LANGAGE_ANIMATION, FOOTER_ANIMATION } from '../../animations/animations';

import { Language } from "../../models/language";

import { ConfigurationService } from '../../services/configuration.service';
import { LanguageService } from '../../services/api/language.service';
import { TranslateConfigService } from '../../services/translate-config.service';
import { EventsService } from '../../services/events.service';


@Component({
	selector: 'app-language-list',
	templateUrl: './language-list.page.html',
	styleUrls: ['./language-list.page.scss'],
	animations: [LANGAGE_ANIMATION, FOOTER_ANIMATION]
})
export class LanguageListPage implements OnInit {

	// animation
	public stateLangage = ENTER_STATE;
	public stateFooter = ENTER_STATE;
	languages:Language [] = [];
	locale: string = '';

	// set locale(locale: string) { this.i18nService.locale = locale }
	constructor(
		public modalController: ModalController,
		public navCtrl: NavController,
		public navParams: NavParams,
		private translateConfig: TranslateConfigService,
		private languageService: LanguageService,
		private configuration: ConfigurationService,
		private eventsService: EventsService,
	) { 
		this.languages = this.languageService.languages;
		this.locale = this.translateConfig.locale;
	}

	ngOnInit(): void {
		setTimeout(() => this.stateLangage =  LEAVE_STATE)
		setTimeout(() => this.stateFooter =  LEAVE_STATE)
	}

	dismiss(event, locale) {
		this.modalController.dismiss({
			'dismissed': true,
			'update': false,
			'locale': locale
		});
	}

	closeModal(locale){
		this.translateConfig.setLanguageDisplay(locale);
		this.translateConfig.setLanguage(locale);
		this.eventsService.updateLocale();
		this.modalController.dismiss({
			'dismissed': true,
			'update': true,
			'locale': locale
		});
	}

}
