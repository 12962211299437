import { Pipe, PipeTransform } from '@angular/core';

import { Article } from '../models/article';

/**
 * Generated class for the ArticleNotShowPipe pipe.
 *
 * See https://angular.io/docs/ts/latest/guide/pipes.html for more info on
 * Angular Pipes.
 */
@Pipe({
  name: 'articleNotShow',
})
export class ArticleNotShowPipe implements PipeTransform {
    /**
     * Filtre un article du bundle categorie
     */
    transform(article: Article[]) {
      if (!article) {
          return article;
      } 
      return article.filter(article=>!article.not_show_nav) }
}
